*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
  }
  a, h1, h2, h3, h4, h5,ul,li,p {
    margin: 0;
    padding: 0;
    text-decoration: none;
    margin-bottom: 0px !important;
  }
  a:hover{
      text-decoration: none;
  }
  
